<template>
    <div class="order_box" v-if="orderdate">
        <div class="title_box" @click="goDetail">
            <div class="avatar">
                <img v-lazy="orderdate.project_thumb" alt="" />
            </div>
            <div class="content" style="width: 80%">
                <h3>{{ orderdate.title }}</h3>
                <p>{{ orderdate.project_created_at }} 创建</p>
            </div>
        </div>
        <div class="order_content">
            <div class="item">
                <p>支持金额</p>
                <h3>{{ orderdate.amount / 100 }} 元</h3>
            </div>
            <div class="item">
                <p>订单状态</p>
                <h3 style="color: #009bff">
                    {{ orderdate.order_state | getStatus }}
                </h3>
            </div>
            <div class="item">
                <p style="flex-shrink: 0">交易单号</p>
                <h3 class="trand_no">{{ orderdate.order_no }}</h3>
            </div>
            <div class="item">
                <p>支付方式</p>
                <h3>{{ orderdate.pay_mode == 1 ? '微信支付' : '其他' }}</h3>
            </div>
            <div class="item">
                <p>交易时间</p>
                <h3>{{ orderdate.pay_date }}</h3>
            </div>
        </div>
        <footer class="back_box">
            <div class="btn" @click="back">返回</div>
        </footer>
    </div>
</template>
<script>
import { getDetail } from './api'
import { shareProj } from '@/common/js/mmcModule';
export default {
    data() {
        return {
            orderdate: ''
        }
    },
    filters: {
        getStatus(e) {
            const state = {
                0: '支付成功',
                1: '退款中',
                2: '已退款',
            }
            return state[e];
        }
    },
    mounted() {
        const { order_no } = this.$route.query;
        const that = this;
        getDetail(order_no).then(res => {
            if (res.code === 0) {
                that.orderdate = res.data;
                shareProj.init({ projuuid: res.data.uuid});
            }else if (res.code === 505) {
               setTimeout(() => {
                   location.replace(`${window.BasePath}fund/publish/guidance`)
               },2000)
            } else {
                setTimeout(() => {
                    history.go(-1);
                }, 2000)
            }
        })
    },
    methods: {
        goDetail() {
            const that = this;
            // this.$router.push({
            //     path: '/fund/project/detail',
            //     query: { projuuid: that.orderdate.uuid }
            // })
            location.href = '/fund/project/detail?projuuid='+that.orderdate.uuid
        },
        back() {
            history.go(-1)
        }
    }
}
</script>
<style scoped lang="less">
.order_box {
    .title_box {
        display: flex;
        justify-content: left;
        width: 89.3%;
        height: 75px;
        background: linear-gradient(90deg, #40b9ff 0%, #009bff 100%);
        margin: 35px auto 5px;
        padding: 14px;
        border-radius: 5px;
        box-shadow: 0 4px 30px 0 rgba(0, 155, 255, 0.4);
        position: relative;
        &:after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            position: absolute;
            right: 20px;
            top: 30px;
        }
        .avatar {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
            }
        }
        .content {
            color: #fff;
            h3 {
                width: 80%;
                text-align: left;
                padding-left: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            p {
                width: 80%;
                text-align: left;
                padding-left: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .order_content {
        width: 100%;
        .item {
            display: flex;
            justify-content: space-between;
            margin: 35px 20px;
            p {
                font-size: 17px;
            }
            h3 {
                font-size: 17px;
                &.trand_no {
                    width: 73%;
                    max-height: 47px;
                    word-break: break-all;
                    text-align: left;
                }
            }
        }
    }
    .back_box {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        .btn {
            width: 100%;
            height: 50px;
            line-height: 50px;
            color: #fff;
            font-size: 17px;
            font-weight: bold;
            text-align: center;
            background-image: linear-gradient(90deg, #40b9ff 0%, #009bff 100%);
        }
    }
}
</style>
